<template>
  <div class="dashboard-kpi-weekly-plan">
    <table class="dashboard-kpi-weekly-plan__list">
      <tbody>
        <tr
          v-for="(detail, i) in kpi.details"
          :key="i"
          class="dashboard-kpi-weekly-plan__item"
        >
          <td class="dashboard-kpi-weekly-plan__title">
            {{ detail.title }}
          </td>

          <td width="99%">
            <div class="dashboard-kpi-weekly-plan__value">
              <div
                v-if="detail.percentage !== undefined"
                class="dashboard-kpi-weekly-plan__percentage"
              >
                {{ `${formatter.formatCompact(detail.percentage)}%` }}
              </div>

              <template v-if="detail.value !== undefined">
                <dashboard-display-number
                  :prefix="detail.prefix"
                  :value="detail.value"
                  :sufix="detail.unity"
                  size="1em"
                  prefix-size="1em"
                ></dashboard-display-number>

                <div
                  v-if="detail.planned !== undefined"
                  class="dashboard-kpi-weekly-plan__planned"
                >
                  <dashboard-display-number
                    :prefix="detail.prefix"
                    :value="detail.planned"
                    :sufix="detail.unity"
                    size="1em"
                    prefix-size="1em"
                  ></dashboard-display-number>
                </div>
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="kpi.showLegend" class="dashboard-kpi-weekly-plan__legend">
      <span class="font-weight-bold primary--text">
        {{ $t('controlTower.pages.cockpitExecution.fulfilled') }}
      </span>
      <span> / {{ $t('controlTower.pages.cockpitExecution.planned') }} </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { useNumberFormatter } from '../../utils/numberFormatter'
import DashboardDisplayNumber from './DashboardDisplayNumber.vue'

export default defineComponent({
  name: 'DashboardKpiWeeklyPlan',
  components: {
    DashboardDisplayNumber
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatter: useNumberFormatter(this)
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-weekly-plan {
  &__list {
    margin-top: 6px;
    border-collapse: collapse;

    td {
      vertical-align: baseline;
      padding-bottom: 9px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: #959db5;
    white-space: nowrap;
    padding-right: 10px;
  }

  &__percentage {
    color: #24bbef;
  }

  &__value {
    display: flex;
    font-size: 16px;
    line-height: 19px;
  }

  &__planned {
    font-weight: normal;
    &::before {
      content: '/';
      padding-inline: 5px;
    }

    ::v-deep .dashboard-display-number {
      color: inherit;
      font-weight: normal;
    }
  }

  &__legend {
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    margin-top: 5px;
  }
}
</style>
