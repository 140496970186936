<template>
  <div class="dashboard-kpi-status">
    <table class="dashboard-kpi-status__list">
      <tbody>
        <tr
          v-for="(detail, i) in details"
          :key="i"
          class="dashboard-kpi-status__item"
        >
          <td>
            <div
              class="dashboard-kpi-status__value"
              :class="valueClass(detail)"
            >
              {{ formatter.formatCompact(detail.value) }}
            </div>
          </td>

          <td class="dashboard-kpi-status__title" width="99%">
            {{ detail.title }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { useNumberFormatter } from '../../utils/numberFormatter'

export default defineComponent({
  name: 'DashboardKpiStatus',
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatter: useNumberFormatter(this)
    }
  },
  methods: {
    valueClass(detail) {
      const baseName = 'dashboard-kpi-status__value'
      switch (detail.status) {
        case 'lost':
          return `${baseName}--lost`
        case 'late':
          return `${baseName}--late`
        case 'risky':
          return `${baseName}--risky`
        case 'inTime':
          return `${baseName}--in-time`
      }
    }
  },
  computed: {
    details() {
      return [
        {
          title: this.$t('controlTower.pages.cockpitExecution.lost'),
          value: this.kpi.lost,
          status: 'lost'
        },
        {
          title: this.$t('controlTower.pages.cockpitExecution.late'),
          value: this.kpi.late,
          status: 'late'
        },
        {
          title: this.$t('controlTower.pages.cockpitExecution.risky'),
          value: this.kpi.risky,
          status: 'risky'
        },
        {
          title: this.$t('controlTower.pages.cockpitExecution.biInTime'),
          value: this.kpi.biInTime,
          status: 'inTime'
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-status {
  &__list {
    margin-top: 5px;
    margin-bottom: -3px;
    border-collapse: collapse;

    td {
      vertical-align: baseline;
    }
  }

  &__value {
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    white-space: nowrap;
    padding-right: 8px;

    &--lost {
      color: white;
    }
    &--late {
      color: #d2354f;
    }
    &--risky {
      color: #e2a13d;
    }
    &--in-time {
      color: #89cd4d;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: normal;
    color: #959db5;
  }
}
</style>
