<template>
  <div class="dashboard-kpi-default">
    <dashboard-display-number
      :prefix="kpi.prefix"
      :value="kpi.value"
      :sufix="kpi.unity"
    />

    <table class="dashboard-kpi-default__detail-list">
      <tbody>
        <dashboard-kpi-default-detail
          v-for="(detail, i) in kpi.details"
          :key="i"
          :detail="detail"
        />
      </tbody>
    </table>

    <div class="dashboard-kpi-default__company-list">
      <div
        v-for="(company, i) in kpi.company"
        :key="i"
        class="dashboard-kpi-default__company"
      >
        <dashboard-kpi-default-detail :detail="company" emphasis />
        <table class="dashboard-kpi-default__detail-list ml-2">
          <tbody>
            <dashboard-kpi-default-detail
              v-for="(detail, i) in company.details"
              :key="i"
              :detail="detail"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardDisplayNumber from './DashboardDisplayNumber.vue'
import DashboardKpiDefaultDetail from './DashboardKpiDefaultDetail.vue'

export default defineComponent({
  name: 'DashboardKpiDefault',
  components: {
    DashboardDisplayNumber,
    DashboardKpiDefaultDetail
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-default {
  &__detail-list {
    list-style: none;
    padding: 0;
    font-size: 13.5px;
    min-height: 21px;
  }

  &__company-list {
    display: flex;
  }

  &__company {
    flex: 1;
    margin-top: 10px;
    padding: 10px;
    background-color: #262c39;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}
</style>
