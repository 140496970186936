import moment from 'moment'

export const DateFormat = {
  Date: 'application.dateFormat',
  DateTime: 'application.dateTimeFormat',
  FullDateTime: 'application.dateTimeFullFormat',
  MonthYearChart: 'application.monthYearChart',
  FullMonthYearChart: 'application.fullMonthYearChart',
  ISODate: 'YYYY-MM-DD'
}

/**
 * Format date to string
 *
 * @param {string | Date | undefined} date Date to be formatted
 * @param {string} format Date format
 * @param {*} i18n i18n instance
 * @returns Formatted date
 */
export const formatDate = (date, format, i18n) => {
  return date == null ? '' : moment(date).format(i18n.$t(format))
}

/**
 * Date formatter composable
 * @param {*} i18n i18n instance
 * @returns A date formatter
 */
export const useDateFormatter = (i18n) => {
  /**
   * Format date to string
   *
   * @param {string | Date | undefined} date Date to be formatted
   * @param {string} format Date format
   * @returns Formatted date
   */
  const formatter = (date, format) => formatDate(date, format, i18n)

  return formatter
}
