var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-kpi-company"},[(_vm.highlightTitle)?_c('div',{staticClass:"dashboard-kpi-company__title"},[_vm._v(" "+_vm._s(_vm.detail.title)+" ")]):_vm._e(),(!_vm.highlightTitle && _vm.detail.title !== 'BR' && _vm.detail.title !== 'RG')?_c('div',{staticClass:"dashboard-kpi-default-detail__title"},[_vm._v(" "+_vm._s(_vm.detail.title)+" ")]):_vm._e(),((!_vm.highlightTitle && _vm.detail.title === 'BR') || _vm.detail.title === 'RG')?_c('div',{staticClass:"dashboard-kpi-company__title"},[_vm._v(" "+_vm._s(_vm.detail.title)+" ")]):_vm._e(),_c('tr',{class:{
      'dashboard-kpi-default-detail': true,
      'dashboard-kpi-default-detail--emphasis': _vm.emphasis
    }},[(_vm.detail.percentage !== undefined)?_c('td',[_c('div',{staticClass:"dashboard-kpi-default-detail__percentage"},[_vm._v(" "+_vm._s(((_vm.formatter.formatCompact(_vm.detail.percentage)) + "%"))+" ")])]):_vm._e(),(_vm.detail.valueIndicator !== undefined)?_c('td',[_c('div',{staticClass:"dashboard-kpi-default-detail__percentage"},[_vm._v(" "+_vm._s(("" + (_vm.formatter.formatCompact(_vm.detail.valueIndicator))))+" ")])]):_vm._e(),(_vm.detail.value !== undefined)?_c('td',[_c('div',{staticClass:"dashboard-kpi-default-detail__value-container"},[(
            (_vm.detail.percentage !== undefined ||
              _vm.detail.valueIndicator !== undefined) &&
            _vm.detail.value !== undefined
          )?_c('div',{staticClass:"dashboard-kpi-default-detail__separator"},[_vm._v(" | ")]):_vm._e(),_c('div',{staticClass:"dashboard-kpi-default-detail__value"},[_c('dashboard-display-number',{attrs:{"value":_vm.detail.value,"sufix":_vm.detail.unity,"size":"1em","sufixSize":"1em"}})],1)])]):_vm._e(),_c('td',{attrs:{"colspan":_vm.detail.percentage === undefined || _vm.detail.value === undefined
          ? 2
          : undefined,"width":"99%"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }