<template>
  <div class="dashboard-kpi-default">
    <div class="dashboard-kpi-default__company-list">
      <div class="dashboard-kpi-default__box">
        <dashboard-display-number
          :prefix="kpi.prefix"
          :value="kpi.value"
          :sufix="kpi.unity"
        />
      </div>
      <div class="dashboard-kpi-default__box">
        <template v-if="kpi.details && kpi.details.length > 1">
          <dashboard-display-number
            v-for="(detail, i) in kpi.details"
            v-bind:key="i"
            :value="detail.value"
            :sufix="kpiDisplayNumberSufix(detail)"
            :size="'20px'"
          />
        </template>
      </div>
    </div>

    <div class="dashboard-kpi-default__company-list">
      <div
        v-for="(group, i) in groupedKpiList"
        :key="i"
        class="dashboard-kpi-default__company"
      >
        <div v-for="(stockDetail, i) in group" :key="i">
          <dashboard-kpi-default-detail :detail="stockDetail" emphasis />
          <table class="dashboard-kpi-default__detail-list ml-2">
            <tbody>
              <dashboard-kpi-default-detail
                v-for="(detail, i) in stockDetail.details"
                :key="i"
                :detail="detail"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardDisplayNumber from './DashboardDisplayNumber.vue'
import DashboardKpiDefaultDetail from './DashboardKpiDefaultDetail.vue'

export default defineComponent({
  name: 'DashboardKpiStockAvailability',
  components: {
    DashboardDisplayNumber,
    DashboardKpiDefaultDetail
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  methods: {
    kpiDisplayNumberSufix(kpi) {
      if (kpi.sufix && kpi.unity) {
        return `${kpi.unity} ${kpi.sufix}`
      }

      if (!kpi.sufix) {
        return kpi.unity
      }
    }
  },
  computed: {
    groupedKpiList() {
      if (this.kpi) {
        return this.kpi?.stockDetails.reduce((groups, item) => {
          const group = groups[item.groupIndex] || []
          group.push(item)
          groups[item.groupIndex] = group
          return groups
        }, {})
      } else {
        return []
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-default {
  &__detail-list {
    list-style: none;
    padding: 0;
    font-size: 14px;
    min-height: 21px;
  }

  &__company-list {
    display: flex;
    min-height: 100px;
  }

  &__company {
    flex: 1;
    margin-top: 10px;
    padding: 10px;
    background-color: #262c39;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__box {
    flex: 1;
    margin-top: 10px;
    padding: 10px;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}
</style>
