<template>
  <component
    class="cargill-section-content light-grey--text"
    :is="tag"
    :class="className"
    :style="styles"
  >
    <slot></slot>
  </component>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { useColorAndLayer } from './styles'

export default defineComponent({
  name: 'CargillSectionContent',
  props: {
    layer: {
      type: [Number, String],
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  setup(props) {
    const { className, styles } = useColorAndLayer(props)
    return { className, styles }
  }
})
</script>

<style lang="scss" scoped>
.cargill-section-content {
  flex: 1;
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
