<template>
  <div class="dashboard-kpi-pd">
    <dashboard-display-number
      :prefix="kpi.prefix"
      :value="kpi.value"
      :sufix="kpi.unity"
    />

    <table class="dashboard-kpi-default__detail-list">
      <tbody>
        <dashboard-kpi-default-detail
          v-for="(detail, i) in kpi.details"
          :key="i"
          :detail="detail"
        />
        <tr>
          <td colspan="100%" style="padding: 0">
            <div class="separator"></div>
          </td>
        </tr>
        <div :class="headerClassName">
          {{ kpi.secondTitle }}
        </div>
        <dashboard-kpi-default-detail
          v-for="(detail, i) in kpi.secondDetails"
          :key="i"
          :detail="detail"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardDisplayNumber from './DashboardDisplayNumber.vue'
import DashboardKpiDefaultDetail from './DashboardKpiDefaultDetail.vue'

export default defineComponent({
  name: 'DashboardKpiPD',
  components: {
    DashboardDisplayNumber,
    DashboardKpiDefaultDetail
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  computed: {
    headerClassName() {
      return {
        'dashboard-kpi-pd__header': true
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-pd {
  &__detail-list {
    list-style: none;
    padding: 0;
    font-size: 13.5px;
    min-height: 21px;
  }

  &__header {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #959db5;
  }

  .separator {
    height: 1px;
    width: 100%;
    background: #3f65aaa6;
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
