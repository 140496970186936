<template>
  <div class="dashboard-kpi-utilization">
    <table class="dashboard-kpi-utilization__list">
      <tbody>
        <tr
          v-for="(utilization, i) in kpi.utilization"
          :key="i"
          class="dashboard-kpi-utilization__item"
        >
          <td>
            <div class="dashboard-kpi-utilization__value">
              {{ formatter.formatCompact(utilization.percentage) }}
            </div>
          </td>

          <td class="dashboard-kpi-utilization__title" width="99%">
            {{ utilization.title }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { useNumberFormatter } from '../../utils/numberFormatter'

export default defineComponent({
  name: 'DashboardKpiUtilization',
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatter: useNumberFormatter(this)
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-utilization {
  &__list {
    margin-top: 5px;
    border-collapse: separate;
    border-spacing: 0 10px;

    td {
      vertical-align: middle;
      padding: 10px 10px;
    }
  }

  &__item {
    background-color: #262c39;

    & + & {
      margin-top: 10px;
    }
  }

  &__value {
    font-size: 32px;
    line-height: 31px;
    color: #24bbef;
    white-space: nowrap;

    &::after {
      content: '%';
      font-size: 0.5em;
      margin-left: -0.3em;
    }
  }

  &__title {
    margin-left: 10px;
    font-size: 16px;
    line-height: 21px;
    font-weight: normal;
    color: #959db5;
  }
}
</style>
