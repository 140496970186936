<template>
  <cargill-section class="dashboard-chart" :layer="3">
    <cargill-section-header class="dashboard-chart__header" color="#2B323F">
      <slot name="header" :chart="chart">
        <div>{{ chart.title }}</div>
      </slot>

      <div class="dashboard-chart__actions">
        <slot name="actions">
          <dashboard-filter
            v-if="filterDefinition != undefined && filterAllowed"
            :filters="filterDefinition"
            :value="filter"
            @input="onApplyFilter"
          ></dashboard-filter>

          <bc-btn
            v-if="exportAllowed"
            icon
            x-small
            class="dashboard-chart__action-btn"
            @click="onExportData"
          >
            <bc-icon size="14" color="light-grey">fas fa-download</bc-icon>
          </bc-btn>
        </slot>
      </div>
    </cargill-section-header>

    <cargill-section-content>
      <slot name="chart" :chart="chart">
        <high-chart
          v-if="chart.chartInput"
          ref="chartRef"
          new-layout
          :chartInputData="chart.chartInput"
          :chart="chart"
          :customOptions="customOptions"
          :is-stacked="!supressChartStack"
          :isVerticalLabel="isVerticalLabel"
          @onChartDataClick="onChartClick"
        >
        </high-chart>
      </slot>
    </cargill-section-content>
  </cargill-section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcBtn, BcIcon } from '@brain/core'

import {
  CargillSection,
  CargillSectionHeader,
  CargillSectionContent
} from '../cargill-section'
import HighChart from '../charts/HighChart.vue'
import DashboardFilter from './DashboardFilter.vue'

export default defineComponent({
  name: 'DashboardChart',
  components: {
    BcBtn,
    BcIcon,
    HighChart,
    CargillSection,
    CargillSectionHeader,
    CargillSectionContent,
    DashboardFilter
  },
  props: {
    baseFilter: {
      type: Object,
      required: true
    },
    onLoad: {
      type: Function,
      required: true
    },
    onExport: {
      type: Function,
      required: true
    },
    supressChartStack: {
      type: Boolean,
      default: false
    },
    isVerticalLabel: {
      type: Boolean,
      default: false
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    allowActions: {
      type: Object,
      default: () => {
        return {
          export: true,
          filter: true
        }
      }
    }
  },
  data() {
    return {
      chart: {},
      filterDefinition: [],
      filter: {},
      exportSvg: false
    }
  },
  async mounted() {
    await this.loadChart()
    this.filterDefinition = this.chart.filters
  },
  methods: {
    async loadChart() {
      this.chart = await this.onLoad(this.chart)
    },
    async onGetSvgData(options) {
      const img = await this.$refs.chartRef.onGetExportSvg(options)
      return img
    },
    async onExportData() {
      await this.onExport(this.chart)
    },
    onApplyFilter(filter) {
      this.filter = filter
      this.chart.filters.forEach((field) => {
        field.modelApplied = filter[field.name]
      })
      this.loadChart()
    },
    onChartClick(event) {
      this.$emit('chartClick', event)
    }
  },
  computed: {
    filterAllowed() {
      if (!this.allowActions) return true

      return this.allowActions.filter
    },
    exportAllowed() {
      if (!this.allowActions) return true

      return this.allowActions.export
    }
  },
  watch: {
    baseFilter: {
      deep: true,
      handler() {
        this.loadChart()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-chart {
  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
  }

  &__actions {
    display: flex;
    align-items: center;
    min-height: 14px;
  }

  &__action-btn {
    margin: -8px 0;
  }
}
</style>
