import { render, staticRenderFns } from "./DashboardKpiCompany.vue?vue&type=template&id=4b50397a&scoped=true&"
import script from "./DashboardKpiCompany.vue?vue&type=script&lang=js&"
export * from "./DashboardKpiCompany.vue?vue&type=script&lang=js&"
import style0 from "./DashboardKpiCompany.vue?vue&type=style&index=0&id=4b50397a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b50397a",
  null
  
)

export default component.exports