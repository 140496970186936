<template>
  <div class="dashboard-unit-selector">
    <div class="dashboard-unit-selector__label">
      {{
        $t('application.pages.monthlyPlanningDashboardRevised.unityMeasure')
      }}:
    </div>

    <div class="dashboard-unit-selector__selector">
      <span :class="optionClass(tonneOption)">
        {{ tonneOption.text }}
      </span>

      <bc-switch
        v-model="selectedOption"
        class="dashboard-unit-selector__switch"
        :false-value="tonneOption"
        :true-value="hcOption"
      ></bc-switch>

      <span :class="optionClass(hcOption)">
        {{ hcOption.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { dashboardUtils } from '@cargill/shared'

const formatUnitOption = (option) => ({
  ...option,
  text: option.text.replace(/\([^)]*\)\w*$/, '')
})

const tonneOption = formatUnitOption(dashboardUtils.getUnityTonne())
const hcOption = formatUnitOption(dashboardUtils.getUnityHc())

export default defineComponent({
  name: 'DashboardUnitSelector',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tonneOption,
      hcOption
    }
  },
  methods: {
    optionClass(option) {
      return {
        'dashboard-unit-selector__option': true,
        'dashboard-unit-selector__option--active':
          this.selectedOption.value === option.value
      }
    }
  },
  computed: {
    selectedOption: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-unit-selector {
  display: flex;
  align-items: center;
  height: 20px;

  &__label {
    margin-right: 10px;
  }

  &__selector {
    display: flex;
    align-items: center;
  }

  &__switch {
    margin-left: 10px;
  }

  &__option {
    font-weight: normal;
    color: #616575;
    transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &--active {
      color: #959db5;
    }
  }

  ::v-deep .v-input--switch .v-input--switch__thumb {
    color: #24bbef;
  }
}
</style>
