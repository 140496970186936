<template>
  <cargill-section-header class="dashboard-date-filter" color="#2B323F">
    <div>
      <span>
        {{
          `${$t(
            'application.pages.monthlyPlanningDashboardRevised.selectedPeriod'
          )}:`
        }}
      </span>
      <span class="font-weight-regular">
        <span class="primary--text">
          {{ horizon.start }}
        </span>
        <span>
          {{ $t('application.misc.till') }}
        </span>
        <span class="primary--text">
          {{ horizon.end }}
        </span>
      </span>
    </div>

    <dashboard-filter
      :filters="filterDefinition"
      v-model="filter"
    ></dashboard-filter>
  </cargill-section-header>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { CargillSectionHeader } from '../cargill-section'
import { useDateFormatter, DateFormat } from '../../utils/dateFormatter'

import DashboardFilter from './DashboardFilter.vue'

export default defineComponent({
  name: 'DashboardDateFilter',
  components: {
    CargillSectionHeader,
    DashboardFilter
  },
  props: {
    minDate: {
      type: [Object, String],
      required: true
    },
    maxDate: {
      type: [Object, String],
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formatDate: useDateFormatter(this)
    }
  },
  computed: {
    horizon() {
      return {
        start: this.formatDate(this.value.start, DateFormat.Date),
        end: this.formatDate(this.value.end, DateFormat.Date)
      }
    },
    filter: {
      get() {
        return { period: [this.value.start, this.value.end] }
      },
      set(newValue) {
        this.$emit('input', {
          start: newValue.period[0],
          end: newValue.period[1]
        })
      }
    },
    filterDefinition() {
      return [
        {
          name: 'period',
          type: 'dateRange',
          minDate: this.minDate,
          maxDate: this.maxDate,
          defaultValue: [this.minDate, this.maxDate]
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-date-filter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 12px;
}
</style>
