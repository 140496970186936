<template>
  <div class="dashboard-kpi-accumulated">
    <table class="dashboard-kpi-accumulated__list">
      <tbody>
        <tr
          v-for="(detail, i) in kpi.details"
          :key="i"
          class="dashboard-kpi-accumulated__item"
        >
          <td class="dashboard-kpi-accumulated__title">
            {{ detail.title }}
          </td>

          <td class="dashboard-kpi-accumulated__value" width="99%">
            <dashboard-display-number
              :prefix="detail.prefix"
              :value="detail.value"
              :sufix="detail.unity"
              size="1em"
            ></dashboard-display-number>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardDisplayNumber from './DashboardDisplayNumber.vue'

export default defineComponent({
  name: 'DashboardKpiAccumulated',
  components: {
    DashboardDisplayNumber
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-accumulated {
  &__list {
    border-collapse: collapse;

    td {
      vertical-align: baseline;
    }
  }

  &__value {
    font-size: 40px;
    line-height: 47px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #959db5;
    white-space: nowrap;
    padding-right: 10px;
  }
}
</style>
