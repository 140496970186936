<template>
  <div class="dashboard-filter">
    <bc-popover v-model="isOpen" placement="bottom-end">
      <template v-slot:activator="{ on, attrs }">
        <bc-btn
          v-bind="attrs"
          v-on="on"
          icon
          x-small
          class="dashboard-filter__button"
        >
          <bc-icon size="14" color="light-grey"> fas fa-filter </bc-icon>
        </bc-btn>
      </template>

      <template v-slot:header>
        <div class="dashboard-filter__header">
          <div class="dashboard-filter__title">
            {{ $t('application.misc.filter') }}
          </div>

          <bc-btn
            class="dashboard-filter__clear-filter"
            color="link-grey"
            @click="onResetFilter"
          >
            {{ $t('controlTower.filters.cleanFilter') }}
          </bc-btn>
        </div>
      </template>

      <div class="dashboard-filter__content">
        <dashboard-filter-input
          v-for="(filter, index) in filters"
          :key="index"
          :filter="filter"
          v-model="filterValues[filter.name]"
        >
        </dashboard-filter-input>

        <div class="dashboard-filter__actions">
          <bc-btn color="link-blue" @click="onCancel">
            {{ $t('application.actions.cancel') }}
          </bc-btn>

          <bc-btn color="primary" @click="onApplyFilter">
            {{ $t('application.actions.save') }}
          </bc-btn>
        </div>
      </div>
    </bc-popover>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcBtn, BcPopover, BcIcon } from '@brain/core'

import DashboardFilterInput from './DashboardFilterInput.vue'

export default defineComponent({
  name: 'DashboardFilter',
  components: {
    BcBtn,
    BcIcon,
    BcPopover,
    DashboardFilterInput
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: false,
      filterValues: {}
    }
  },
  methods: {
    updateFilterValues() {
      this.filterValues = this.filters.reduce(
        (values, filter) => ({
          ...values,
          [filter.name]:
            this.value[filter.name] !== undefined
              ? this.value[filter.name]
              : filter.defaultValue ?? null
        }),
        {}
      )
    },
    onApplyFilter() {
      this.$emit('input', {
        ...this.filterValues
      })
      this.isOpen = false
    },
    onResetFilter() {
      this.filterValues = this.filters.reduce(
        (values, filter) => ({
          ...values,
          [filter.name]: filter.defaultValue ?? null
        }),
        {}
      )
    },
    onCancel() {
      this.isOpen = false
    }
  },
  watch: {
    value() {
      this.updateFilterValues()
    },
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.updateFilterValues()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-filter {
  margin: -8px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    color: #959db5;
    font-weight: bold;
  }

  &__clear-filter {
    &#{&}#{&} {
      background-color: transparent;
      padding: 0 20px !important;
      min-height: 48px;
      height: 48px;
      margin: -12px -20px -12px 0;
    }
  }

  &__content {
    min-width: 400px;
    padding-top: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
</style>
