import { computed, inject } from '@vue/composition-api'

const isColorLiteral = (color) =>
  color &&
  (color.startsWith('#') || color.startsWith('rgb') || color.startsWith('hsl'))

const getStyles = (color) =>
  isColorLiteral(color) ? `background-color: ${color}` : ''

const getColorClass = (color) => {
  return !!color && !isColorLiteral(color) ? color : ''
}

const getLayerClass = (color, layer) => {
  return !color && layer !== undefined ? `layer-${layer}` : ''
}

const getClasses = (color, layer) => {
  const layerClass = getLayerClass(color, layer)
  const colorClass = getColorClass(color)
  return `${layerClass} ${colorClass}`
}

const useColorAndLayer = (props) => {
  const parentColor = inject('color')
  const parentLayer = inject('layer')
  const actualColor = computed(() => props.color || parentColor?.value)
  const actualLayer = computed(() => props.layer || parentLayer?.value || 2)

  const className = computed(() => {
    return getClasses(actualColor.value, actualLayer.value)
  })
  const styles = computed(() => getStyles(actualColor.value, actualLayer.value))

  return { className, styles, color: actualColor, layer: actualLayer }
}

export { useColorAndLayer }
