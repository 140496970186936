<template>
  <div class="dashboard-kpi-default">
    <div class="dashboard-kpi-default__display-number">
      <dashboard-display-number
        :prefix="kpi.prefix"
        :value="kpi.percentageExecutedVolume"
        :sufix="'%'"
      />
      <div
        v-if="
          kpi.percentageExecutedVolume !== undefined &&
          kpi.valueExecutedTotal !== undefined
        "
        class="dashboard-kpi-default__display-number-separator"
      >
        |
      </div>
      <dashboard-display-number
        :prefix="kpi.prefix"
        :value="kpi.valueExecutedTotal"
        :sufix="kpi.unity"
      />
    </div>

    <div
      class="dashboard-kpi-default__container"
      v-bind:class="
        kpi.percentageExecutedVolume == undefined
          ? 'dashboard-kpi-default__container'
          : 'dashboard-kpi-default__container2'
      "
    >
      <div class="dashboard-kpi-default__chart">
        <high-chart :chartInputData="chart" class="offset"></high-chart>
      </div>

      <div
        v-if="kpi.company.length != 0"
        class="dashboard-kpi-default__company-list"
      >
        <div
          v-for="(company, i) in kpi.company"
          :key="i"
          class="dashboard-kpi-default__company"
        >
          <dashboard-kpi-company
            v-if="company.title !== 'BR' && company.title !== 'RG'"
            :detail="company"
          />
          <div v-else class="dashboard-kpi-default__company-title">
            {{ company.title }}
          </div>
          <div v-for="(detail, j) in company.details" :key="j">
            <dashboard-kpi-default-detail :detail="detail" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import HighChart from '../charts/HighChart.vue'
import DashboardDisplayNumber from './DashboardDisplayNumber.vue'
import DashboardKpiCompany from './DashboardKpiCompany.vue'
import DashboardKpiDefaultDetail from './DashboardKpiDefaultDetail.vue'

export default defineComponent({
  name: 'DashboardKpiGauge',
  components: {
    HighChart,
    DashboardDisplayNumber,
    DashboardKpiCompany,
    DashboardKpiDefaultDetail
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  },
  computed: {
    chart() {
      return {
        chartDataSets: [
          {
            name: 'gauge',
            type: 'gauge',
            defaultBackgroundColor: 'transparent',
            data: [this.kpi.gague]
          }
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-default {
  height: 100%;
  &__display-number {
    color: #24bbef;
    font-weight: bold;
    display: flex;
  }

  &__display-number-separator {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 32px;
    font-weight: normal;
    float: left;
  }

  &__container {
    display: flex;
  }

  &__container2 {
    display: flex;
    height: 70%;
    align-items: center;
  }

  &__chart {
    margin: 6px -10px -9px;
    padding-top: 10px;
    max-height: 100px;
    overflow: hidden;
  }

  &__company-list {
    flex: 1;
    margin: 10px 0px 0 8px;
    padding: 0px 7px 5px 7px;
    background-color: #262c39;
    max-width: 45%;
    min-width: 48%;
    font-size: 12px;
    overflow: hidden;
  }

  &__company-title {
    font-weight: normal;
    margin-top: 7px;
  }
}
</style>
