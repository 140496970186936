<template>
  <component
    class="cargill-section-header light-grey--text"
    :is="tag"
    :class="className"
    :style="styles"
  >
    <slot></slot>
    <bc-tooltip top color="#404655" v-if="tooltip">
      <template v-slot:activator="{ on, attrs }">
        <bc-icon
          v-bind="attrs"
          v-on="on"
          style="margin-left: auto"
          color="#24bbef"
          size="16"
          >fa-info-circle</bc-icon
        >
      </template>
      <div class="cargill-section-header__tooltip">
        {{ tooltip }}
      </div>
    </bc-tooltip>
  </component>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcTooltip, BcIcon } from '@brain/core'
import { useColorAndLayer } from './styles'

export default defineComponent({
  name: 'CargillSectionHeader',
  components: {
    BcTooltip,
    BcIcon
  },
  props: {
    layer: {
      type: [Number, String],
      default: undefined
    },
    color: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'header'
    },
    tooltip: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    var { className, styles } = useColorAndLayer(props)
    if (props.tooltip !== undefined) {
      styles = styles.value + '; display: flex'
    }
    return { className, styles }
  }
})
</script>

<style lang="scss" scoped>
.cargill-section-header {
  width: 100%;
  padding: 12px 20px;
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
