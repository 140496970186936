<template>
  <bc-btn
    :class="classNames"
    v-bind="$attrs"
    v-on="$listeners"
    :icon="!!icon"
    x-small
  >
    <bc-icon v-if="icon" size="14" color="light-grey">{{ icon }}</bc-icon>
    <slot></slot>
  </bc-btn>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcBtn, BcIcon } from '@brain/core'

export default defineComponent({
  name: 'CarginSectionHeaderBtn',
  components: { BcBtn, BcIcon },
  props: {
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    classNames() {
      return {
        'cargin-section-header-btn': true,
        'cargin-section-header-btn--icon': !!this.icon,
        'cargin-section-header-btn--text': !this.icon
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cargin-section-header-btn {
  margin-block: -8px;

  &:last-child {
    margin-inline-end: -8px;
  }

  &--text {
    &#{&}#{&} {
      font-size: 14px;
      padding: 0 8px !important;
    }
  }
}
</style>
