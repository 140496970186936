<template>
  <div class="dashboard-filter-input" :id="inputId">
    <!-- DATE -->
    <bc-menu
      v-if="filter.type === 'date'"
      v-model="isMenuOpen"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="400"
      :attach="`#${inputId}`"
    >
      <template v-slot:activator="{ on, attrs }">
        <bc-text-field
          v-model="filterValue"
          :label="filter.label"
          append-icon="fa-calendar-alt"
          readonly
          v-bind="attrs"
          v-on="on"
          filled
        ></bc-text-field>
      </template>

      <bc-date-picker
        v-model="filterValue"
        @input="isMenuOpen = false"
      ></bc-date-picker>
    </bc-menu>

    <!-- DATE RANGE -->
    <datepicker-range
      v-if="filter.type === 'dateRange'"
      v-model="filterValue"
      :minDate="filter.minDate"
      :maxDate="filter.maxDate"
      :attach="`#${inputId}`"
    />

    <!-- SELECT -->
    <bc-select
      v-if="filter.type === 'select' || filter.type === 'multiSelect'"
      filled
      item-text="text"
      item-value="value"
      :menu-props="{ auto: true }"
      :returnObject="false"
      v-bind="filter"
      v-model="filterValue"
      :items="filter.value"
      :multiple="filter.type == 'multiSelect'"
      :attach="`#${inputId}`"
    ></bc-select>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcMenu, BcSelect, BcDatePicker, BcTextField } from '@brain/core'

import DatepickerRange from '../date-picker/DatepickerRange.vue'

let inputIdGenerator = 1

export default defineComponent({
  name: 'DashboardFilterInput',
  components: {
    BcMenu,
    BcSelect,
    BcTextField,
    BcDatePicker,
    DatepickerRange
  },
  props: {
    value: {
      type: [Array, String, Object]
    },
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isMenuOpen: false,
      inputId: `dashboard-filter-input-${inputIdGenerator++}`
    }
  },
  computed: {
    filterValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-filter-input {
  position: relative;

  & + & {
    margin-top: 10px;
  }
}
</style>
