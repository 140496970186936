import { render, staticRenderFns } from "./DashboardKpiProgramPD.vue?vue&type=template&id=38607125&scoped=true&"
import script from "./DashboardKpiProgramPD.vue?vue&type=script&lang=js&"
export * from "./DashboardKpiProgramPD.vue?vue&type=script&lang=js&"
import style0 from "./DashboardKpiProgramPD.vue?vue&type=style&index=0&id=38607125&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38607125",
  null
  
)

export default component.exports