<template>
  <tr
    :class="{
      'dashboard-kpi-default-detail': true,
      'dashboard-kpi-default-detail--emphasis': emphasis
    }"
  >
    <td v-if="detail.percentage !== undefined">
      <div class="dashboard-kpi-default-detail__percentage">
        {{ `${formatter.formatCompact(detail.percentage)}%` }}
      </div>
    </td>

    <td v-if="detail.valueIndicator !== undefined">
      <div class="dashboard-kpi-default-detail__percentage">
        {{ `${formatter.formatCompact(detail.valueIndicator)}` }}
      </div>
    </td>

    <td v-if="detail.value !== undefined">
      <div class="dashboard-kpi-default-detail__value-container">
        <div
          v-if="(detail.percentage !== undefined || detail.valueIndicator !== undefined) && 
                detail.value !== undefined"
          class="dashboard-kpi-default-detail__separator"
        >
          |
        </div>

        <div class="dashboard-kpi-default-detail__value">
          <dashboard-display-number
            :value="detail.value"
            :sufix="detail.unity"
            size="1em"
            sufixSize="1em"
          ></dashboard-display-number>
        </div>
      </div>
    </td>

    <td
      :colspan="
        detail.percentage === undefined || detail.value === undefined
          ? 2
          : undefined
      "
      width="99%"
    >
      <div class="dashboard-kpi-default-detail__title" v-if="detail.title !== 'BR' && detail.title !== 'RG'">
        {{ detail.title }}
      </div>
      <div v-else class="dashboard-kpi-default-detail__title"> <b>{{ detail.title }}</b></div>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { useNumberFormatter } from '../../utils/numberFormatter'
import DashboardDisplayNumber from './DashboardDisplayNumber.vue'

export default defineComponent({
  name: 'DashboardKpiDefaultDetail',
  components: { DashboardDisplayNumber },
  props: {
    detail: {
      type: Object,
      required: true
    },
    emphasis: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatter: useNumberFormatter(this)
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-default-detail {
  color: #24bbef;
  font-weight: bold;

  &__value-container {
    display: flex;
    white-space: nowrap;
  }

  &__percentage {
    white-space: nowrap;
  }

  &__separator {
    margin-left: 5px;
    margin-right: 5px;
  }

  &__title {
    margin-left: 5px;
    font-weight: normal;
    color: #959db5;
  }

  &--emphasis {
    .dashboard-kpi-default-detail__title {
      font-weight: bold;
      color: inherit;
    }
  }
}
</style>
