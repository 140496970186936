<template>
  <div class="dashboard-kpi-default">
    <dashboard-display-number
      :prefix="kpi.unity === 'R$' ? kpi.unity : kpi.prefix"
      :value="kpi.value"
      :sufix="kpi.unity !== 'R$' ? kpi.unity : kpi.sufix"
    />

    <div class="dashboard-kpi-default__company">
      <table class="dashboard-kpi-default__detail-list">
        <tbody>
          <dashboard-kpi-default-detail
            v-for="(detail, i) in kpi.details"
            :key="i"
            :detail="detail"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import DashboardDisplayNumber from './DashboardDisplayNumber.vue'
import DashboardKpiDefaultDetail from './DashboardKpiDefaultDetail.vue'
import DashboardKpiCompany from './DashboardKpiCompany.vue'

export default defineComponent({
  name: 'DashboardKpiProgramPD',
  components: {
    DashboardDisplayNumber,
    DashboardKpiDefaultDetail,
    DashboardKpiCompany
  },
  props: {
    kpi: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-kpi-default {
  &__detail-list {
    list-style: none;
    padding: 0;
    font-size: 12px;
    min-height: 21px;
  }

  &__company-list {
    display: flex;
  }

  &__company {
    flex: 1;
    margin-top: 10px;
    padding: 5px 7px;
    background-color: #262c39;

    &:not(:last-of-type) {
      margin-right: 7px;
    }
  }
}
</style>
