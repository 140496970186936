<template>
  <bc-tooltip right color="#404655">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="dashboard-display-number"
        :style="{ 'font-size': size }"
      >
        <span
          v-if="prefix"
          class="dashboard-display-number__prefix"
          :style="{ 'font-size': prefixSize }"
        >
          {{ prefix }}
        </span>
        <span class="dashboard-display-number__value">
          {{ numberFormatter.formatCompact(value) }}
        </span>
        <span
          v-if="sufix"
          class="dashboard-display-number__sufix"
          :style="{ 'font-size': sufixSize }"
        >
          {{ sufix }}
        </span>
      </div>
    </template>

    <div class="dashboard-display-number__tooltip">
      {{ tooltip }}
    </div>
  </bc-tooltip>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcTooltip } from '@brain/core'

import { useNumberFormatter } from '../../utils/numberFormatter'

export default defineComponent({
  name: 'DashboardDisplayNumber',
  components: {
    BcTooltip
  },
  props: {
    prefix: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      required: true
    },
    sufix: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '30px'
    },
    prefixSize: {
      type: String,
      default: '0.6em'
    },
    sufixSize: {
      type: String,
      default: '0.6em'
    }
  },
  data() {
    return {
      numberFormatter: useNumberFormatter(this)
    }
  },
  computed: {
    tooltip() {
      return `${this.prefix} ${this.numberFormatter.format(this.value)} ${
        this.sufix
      }`
    }
  }
})
</script>

<style lang="scss" scoped>
.dashboard-display-number {
  color: #24bbef;
  font-weight: bold;

  &__prefix {
    font-size: 0.6em;
    margin-right: 0.2em;
  }

  &__sufix {
    font-size: 0.6em;
    margin-left: 0.25em;
  }

  &__tooltip {
    text-transform: uppercase;
  }
}
</style>
