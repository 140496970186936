<template>
  <component
    class="cargill-section"
    :class="{ 'cargill-section--spaced': spaced }"
    :is="tag"
  >
    <slot></slot>
  </component>
</template>

<script>
import { defineComponent, provide, toRef } from '@vue/composition-api'

export default defineComponent({
  name: 'CargillSection',
  props: {
    layer: {
      type: [Number, String],
      default: 2
    },
    color: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'section'
    },
    spaced: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const layer = toRef(props, 'layer')
    const color = toRef(props, 'color')
    provide('layer', layer)
    provide('color', color)
  }
})
</script>

<style lang="scss" scoped>
.cargill-section {
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 2px;
  }

  &--spaced {
    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
